// extracted by mini-css-extract-plugin
export var article = "article-page-module--article--3Kert";
export var attribute = "article-page-module--attribute--x1N-u";
export var attributes = "article-page-module--attributes--cEBFQ";
export var author = "article-page-module--author--jxK9I";
export var authorName = "article-page-module--authorName--Fm1Lm";
export var authorPhoto = "article-page-module--authorPhoto--D0gwE";
export var authorPosition = "article-page-module--authorPosition--e3V7P";
export var breadcrumbs = "article-page-module--breadcrumbs--E4mGQ";
export var breadcrumbsItem = "article-page-module--breadcrumbsItem--jgT2f";
export var content = "article-page-module--content--t8cFU";
export var contentLayout = "article-page-module--contentLayout--WPRVB";
export var contentPicture = "article-page-module--contentPicture--smWMv";
export var contentPictureWrapper = "article-page-module--contentPictureWrapper--EHukm";
export var copyButton = "article-page-module--copyButton--TWikN";
export var copyButtonDone = "article-page-module--copyButtonDone--f1nsu";
export var cover = "article-page-module--cover--SvjsC";
export var coverWrapper = "article-page-module--coverWrapper--HM+7N";
export var extraInfoAuthorWrapper = "article-page-module--extraInfoAuthorWrapper--qcXaW";
export var extraInfoColumn = "article-page-module--extraInfoColumn--5whnX";
export var followUsWrapper = "article-page-module--followUsWrapper--hchnD";
export var mainContentColumn = "article-page-module--mainContentColumn--guwHe";
export var mainContentColumnAuthor = "article-page-module--mainContentColumnAuthor--LgWf4";
export var paddedContent = "article-page-module--paddedContent---Rg6L";
export var page = "article-page-module--page--l6MXE";
export var pageContent = "article-page-module--pageContent--6YDZG";
export var promoBlock = "article-page-module--promoBlock--gf-rj";
export var promoBlockContent = "article-page-module--promoBlockContent--w5Spb";
export var promoCtaButton = "article-page-module--promoCtaButton--mMjfS";
export var promoCtaWrapper = "article-page-module--promoCtaWrapper--UJlga";
export var promoPicture = "article-page-module--promoPicture--cb0O1";
export var sharingBlock = "article-page-module--sharingBlock--cQdRf";
export var sharingIcon = "article-page-module--sharingIcon--9M-Y7";
export var sharingIconList = "article-page-module--sharingIconList--6vnCK";
export var sharingLink = "article-page-module--sharingLink--emmfU";
export var sharingTitle = "article-page-module--sharingTitle--Uef8R";
export var title = "article-page-module--title--Dd8+A";
export var youtubeIframe = "article-page-module--youtubeIframe--gmOuZ";
export var youtubeWrapper = "article-page-module--youtubeWrapper--YwqT0";