import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';

import FacebookIcon from '../images/sharing/facebook.svg';
import TwitterIcon from '../images/sharing/twitter.svg';
import LinkedInIcon from '../images/sharing/linkedin.svg';
import CopyIcon from '../images/sharing/copy.svg';

import * as Text from '../localisation';
import { Url } from '../url';
import SecondaryPageLayout from '../components/secondary-page-layout';
import Meta, { OpenGraphPageType } from '../components/meta';
import FollowUs from '../components/follow-us';

import * as classes from './article-page.module.css';

enum SharingLinkType {
  Facebook = 'facebook',
  Twitter = 'twitter',
  Linkedin = 'linkedin',
}

type SharingLinkProps = {
  pageUrl: string,
  type: SharingLinkType,
};

function SharingLink({ pageUrl, type }: SharingLinkProps) {
  let icon;
  let alt;
  let providerUrl;

  const trackedPageUrl = encodeURIComponent(`${pageUrl}?utm_campaign=sharing&utm_source=${type}`);

  switch (type) {
    case SharingLinkType.Facebook:
      icon = FacebookIcon;
      alt = Text.BlogPostShareFacebook;
      providerUrl = `https://www.facebook.com/sharer.php?u=${trackedPageUrl}`;
      break;

    case SharingLinkType.Twitter:
      icon = TwitterIcon;
      alt = Text.BlogPostShareTwitter;
      providerUrl = `https://twitter.com/intent/tweet?url=${trackedPageUrl}`;
      break;

    case SharingLinkType.Linkedin:
      icon = LinkedInIcon;
      alt = Text.BlogPostShareLinkedIn;
      providerUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${trackedPageUrl}`;
      break;
  }

  return (
    <a
      href={providerUrl}
      className={classes.sharingLink}
      rel="noreferrer"
      target="_blank"
    >
      <img
        src={icon}
        alt={alt}
        className={classes.sharingIcon}
      />
    </a>
  );
}

function ArticleAuthor({ author }: { author: any }) {
  return (
    <div className={classes.author}>
      <GatsbyImage
        className={classes.authorPhoto}
        image={getImage(author.avatar.localFile)}
        alt={author.avatar.alternativeText}
      />
      <div>
        <div className={classes.authorName}>
          {author.firstName}
          {' '}
          {author.lastName}
        </div>
        <div className={classes.authorPosition}>
          {author.companyPosition}
        </div>
      </div>
    </div>
  );
}

enum ArticleContentComponent {
  RichText = 'article.rich-text',
  Media = 'article.media',
  YouTube = 'article.you-tube',
}

function ArticlePageTemplate(props) {
  const {
    title,
    url,
    date,
    timeToRead,
    picture,
    keywords,
    sharingText,
    sharingPicture,
    updatedAt,
    author,
    category,
    promo,
    content,
  } = props.data.strapiArticle;

  const [copyNotificationShown, setCopyNotificationShown] = useState(false);
  const copyNotificationTimerRef = useRef(null);

  const sharingUrl = process.env.GATSBY_EMERY_ENV === 'production'
    ? `https://emery.to/blog/${url}/`
    : `https://emery-staging.web.app/blog/${url}/`;

  function handleCopy() {
    navigator.clipboard.writeText(`${sharingUrl}?utm_campaign=sharing&utm_source=copy-link`);

    setCopyNotificationShown(true);

    window.clearTimeout(copyNotificationTimerRef.current);
    copyNotificationTimerRef.current = window.setTimeout(() => {
      setCopyNotificationShown(false);
    }, 2000);
  }

  useEffect(() => {
    window.clearTimeout(copyNotificationTimerRef.current);
  }, []);

  return (
    <SecondaryPageLayout
      contentClassName={classes.pageContent}
      pageClassName={classes.page}
    >
      <Meta
        title={Text.getBlogArticlePageTitle(title)}
        url={`/blog/${url}/`}
        keywords={keywords}
        description={sharingText}
        picture={sharingPicture?.localFile?.publicURL}
        type={OpenGraphPageType.Article}
      />

      <Helmet>
        <meta property="article:published_time" content={date} />
        <meta property="article:modified_time" content={updatedAt} />
        <meta property="article:author" content={`${author.firstName} ${author.lastName}`} />
        <meta property="author" content={`${author.firstName} ${author.lastName}`} />
        <meta property="article:section" content={category.title} />
        {keywords.split(',').map((keyword) => keyword.trim() ? (
          <meta key={keyword.trim()} property="article:tag" content={keyword.trim()} />
        ) : null)}
      </Helmet>

      <article className={classes.article}>
        <div className={classes.paddedContent}>
          <ol className={classes.breadcrumbs}>
            <li className={classes.breadcrumbsItem}>
              <Link to={Url.Blog}>
                {Text.BlogPostParent}
              </Link>
            </li>
            <li className={classes.breadcrumbsItem}>
              {category.title}
            </li>
          </ol>

          <h1 className={classes.title}>{title}</h1>

          <div className={classes.attributes}>
            <div className={classes.attribute}>
              <CalendarMonthOutlinedIcon />
              <time dateTime={date}>
                {format(new Date(date), 'MMMM d, yyyy')}
              </time>
            </div>

            <div className={classes.attribute}>
              <AvTimerOutlinedIcon />
              {timeToRead}
            </div>
          </div>
        </div>

        <div className={classes.coverWrapper}>
          <GatsbyImage
            className={classes.cover}
            image={getImage(picture?.localFile)}
            alt={picture?.alternativeText}
          />
        </div>

        <div className={classes.paddedContent}>
          <div className={classes.contentLayout}>
            <div className={classes.extraInfoColumn}>
              <div className={classes.extraInfoAuthorWrapper}>
                <ArticleAuthor author={author} />
              </div>

              <div className={classes.sharingBlock}>
                <div className={classes.sharingTitle}>
                  {Text.BlogPostShare}
                </div>

                <div className={classes.sharingIconList}>
                  <SharingLink
                    pageUrl={sharingUrl}
                    type={SharingLinkType.Facebook}
                  />

                  <SharingLink
                    pageUrl={sharingUrl}
                    type={SharingLinkType.Twitter}
                  />

                  <SharingLink
                    pageUrl={sharingUrl}
                    type={SharingLinkType.Linkedin}
                  />

                  <button
                    type="button"
                    className={classes.copyButton}
                    onClick={handleCopy}
                  >
                    <img
                      src={CopyIcon}
                      alt={Text.BlogPostShareCopy}
                      className={classes.sharingIcon}
                    />

                    {copyNotificationShown ? (
                      <span className={classes.copyButtonDone}>{Text.BlogPostShareCopyDone}</span>
                    ) : null}
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.mainContentColumn}>
              <div className={classes.mainContentColumnAuthor}>
                <ArticleAuthor author={author} />
              </div>

              <div className={classes.content}>
                {content.map((component) => {
                  switch (component.strapi_component) {
                    case ArticleContentComponent.YouTube:
                      return (
                        <div
                          className={classes.youtubeWrapper}
                          style={{
                            paddingTop: `${component.aspectRatio}%`,
                          }}
                          key={component.id}
                        >
                          <iframe
                            className={classes.youtubeIframe}
                            width="100%"
                            src={component.YouTube}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen

                          />
                        </div>
                      );

                    case ArticleContentComponent.RichText:
                      return (
                        <div
                          key={component.id}
                          dangerouslySetInnerHTML={{
                            __html: component.body.data.childMarkdownRemark.html,
                          }} />
                      );

                    case ArticleContentComponent.Media:
                      return getImage(component.file.localFile) ? (
                        <div className={classes.contentPictureWrapper}>
                          <GatsbyImage
                            className={classes.contentPicture}
                            key={component.id}
                            image={getImage(component.file.localFile)}
                            alt={component.file.alternativeText}
                            imgStyle={{
                              // objectFit: 'contain',
                            }}
                          />
                        </div>
                      ) : (
                        <div className={classes.contentPictureWrapper}>
                          <img
                            className={classes.contentPicture}
                            key={component.id}
                            src={component.file.localFile.publicURL}
                            alt={component.file.alternativeText}
                          />
                        </div>
                      )
                  }
                })}
              </div>
            </div>

            <div>
              <div className={classes.promoBlock}>
                <div
                  className={`${classes.content} ${classes.promoBlockContent}`}
                  dangerouslySetInnerHTML={{ __html: promo.text.data.childMarkdownRemark.html }}
                />

                <div className={classes.promoCtaWrapper}>
                  <Link
                    className={classes.promoCtaButton}
                    to={promo.ctaUrl}>
                    {promo.ctaText}
                  </Link>
                </div>

                {promo.picture && getImage(promo.picture.localFile) ? (
                  <GatsbyImage
                    className={classes.promoPicture}
                    image={getImage(promo.picture.localFile)}
                    alt={promo.picture.alternativeText}
                  />
                ) : null}

                {promo.picture && !getImage(promo.picture.localFile) ? (
                  <img
                    className={classes.promoPicture}
                    src={promo.picture.localFile.publicURL}
                    alt={promo.picture.alternativeText}
                  />
                ) : null}
              </div>

              <div className={classes.followUsWrapper}>
                <FollowUs />
              </div>
            </div>

          </div>
        </div>
      </article>

    </SecondaryPageLayout>
  )
}

export const query = graphql`
  query ($url: String) {
    strapiArticle(url: { eq: $url }) {
      title
      url
      date
      timeToRead
      picture {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      keywords
      sharingText
      sharingPicture {
        localFile {
          publicURL
        }
      }
      updatedAt
      author {
        firstName
        lastName
        companyPosition
        avatar {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      category {
        title
        url
      }
      promo {
        text {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        ctaText
        ctaUrl
        picture {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      content {
        ... on STRAPI__COMPONENT_ARTICLE_RICH_TEXT {
          id
          strapi_component
          body {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_ARTICLE_MEDIA {
          id
          strapi_component
          file {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
        }
        ... on STRAPI__COMPONENT_ARTICLE_YOU_TUBE {
          id
          strapi_component
          YouTube
          aspectRatio
        }
      }
    }
  }
`;

export default ArticlePageTemplate;
